const FLAT_MODAL_CARD_ID = 'flat-cube-modal';

function createModalFlatCard ({flatUrl, modalUrl}) {
  const $modal = $(`
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="${FLAT_MODAL_CARD_ID}">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">            
          <div class="modal-body">
            
          </div>
          <div class="modal-footer clearfix">
            <button class="btn btn-outline-secondary" data-dismiss="modal">Закрыть</button>
            <a href="${flatUrl}" class="btn btn-primary">Карточка помещения</a>
          </div>
        </div>
      </div>
    </div>
  `);

  $('body').append($modal);
  $modal.modal('show');

  $modal.find('.modal-body').load(modalUrl);
}

function removeModalFlatCard () {
  $(`#${FLAT_MODAL_CARD_ID}`).modal('hide');
  $('.modal').remove();
  $('.modal-backdrop').remove();
  $('body').removeClass('modal-open');
}

function initModalFlatCard () {

  if (document.getElementById(FLAT_MODAL_CARD_ID)) {
    removeModalFlatCard();
  }

  $('[data-toggle="ajaxModalFlat"]').on('click', function (e) {
    // При клике по кубику с контролом/коммандом открываем ссылку в фоновой вкладке
    if (e.metaKey) {
      return true;
    }

    e.preventDefault();

    const $target = $(e.currentTarget);

    createModalFlatCard({
      flatUrl: $target.attr('href'),
      modalUrl: $target.data('remote')
    });

    return false;
  });
}

/**
 * При переходе на другую страницу скрываем модальное окно с карточкой помещения,
 * чтобы турболинки не показали его при возврате на исходную страницу
 */
$(document).on('turbolinks:before-visit', removeModalFlatCard);

/**
 * Включаем открытие модального окна при клике на «кубик»
 */
$(document).on('turbolinks:load', initModalFlatCard);