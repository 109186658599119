import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.ru.min'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css'

$(document).on('turbolinks:load', () => {

  $('[data-is-datepicker="true"]').each(function(index, el) {

    let min = $(el).attr('min');
    let max = $(el).attr('max');

    $(el).datepicker({
      format: 'dd.mm.yyyy',
      language: 'ru',

      todayHighlight: true,
      autoclose: true,
      clearBtn: true,

      orientation: "bottom left",
      container: '.site__content',
      startDate: min,
      endDate: max
    });

  });

});