// <dl class="edu-facet" edu-facet="true" is-compact="false" is-opened="false">

import * as $ from 'jquery';

$(document).on('turbolinks:load', function () {

  $('[edu-facet="true"]').each(function (index, facet) {

    const $facet = $(facet);

    $facet.find('dt[edu-facet-title="true"]').on('click.facet', e => {
      e.preventDefault();
      e.stopImmediatePropagation();

      console.log(e.target);

      const currValue = $facet.attr('is-opened') === 'true';
      const newValue  = !currValue;

      console.log(currValue, newValue);
      $facet.attr('is-opened', `${newValue}`);
    });
  });

});