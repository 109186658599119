$(document).on('turbolinks:load', function () {

  // Скрипт для создания поля ввода типа «Карта»
  const GOOGLE_MAPS_API_KEY = 'AIzaSyA7uo7FrVhqoachtef4mqQ6lrAIMaYchzo';
  const CALLBACK_NAME       = 'initMapsFields';

  const $inputs = $('[data-input-map]');
  if ($inputs.length === 0) {
    return;
  }

  insertMapsScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=${CALLBACK_NAME}&libraries=places`);

  // Глобальный колбэк. После загрузки карт создаёт карты для каждого поля ввода
  window.initMapsFields = function initMapsFields () {
    $inputs.each((index, input) => initMapField(input))
  };

  // Вставляем после поля ввода карту с маркером
  // Клик по карте перемещает маркер и обновляет значение в поле ввода

  function initMapField (inputEl) {

    let geo;

    const HOLDER_CLASS = 'kvg-map-holder';

    if(!!inputEl.value) {
      geo = `${inputEl.value}`.split(',').map(parseFloat);
    } else {
      geo = `${inputEl.dataset.inputDefault}`.split(',').map(parseFloat);
    }

    Array.from(document.getElementsByClassName(HOLDER_CLASS)).forEach(r => r.remove());

    const holder = document.createElement('div');
    holder.classList.add(HOLDER_CLASS);


    inputEl.parentNode.insertBefore(holder, inputEl.nextSibling);

    const mapId = `map-field-${+new Date}`;
    const mapEl = document.createElement('div');

    mapEl.id           = mapId;
    mapEl.style.width  = '100%';
    mapEl.style.height = '320px';

    const autoInput = document.createElement('input');
    autoInput.type  = 'text';
    autoInput.classList.add('form-control');
    autoInput.placeholder = 'Найдите и укажите расположение на карте';
    autoInput.style.marginTop = '6px';

    autoInput.addEventListener('keydown', event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        return false;
      }
    });

    // insert map element
    holder.appendChild(autoInput);
    holder.appendChild(mapEl);

    const centerGeo = {lat: geo[0], lng: geo[1]};

    const map = new google.maps.Map(document.getElementById(mapId), {
      center: centerGeo,
      zoom: 10,
      scaleControl: false,
      mapTypeControl: true,
      streetViewControl: false,
      fullscreenControl: false
    });

    const marker = new google.maps.Marker({
      position: centerGeo,
      map: map
    });

    const autocomplete = new google.maps.places.Autocomplete(autoInput);
    autocomplete.addListener('place_changed', onPlaceChanged);

    function onPlaceChanged () {
      const place = autocomplete.getPlace();

      if (place.geometry) {
        moveMarker(place.geometry.location);
        map.panTo(place.geometry.location);
      }

      autoInput.value = '';
    }

    function moveMarker (latLng) {
      marker.setPosition(latLng);
      dumpGeoPosition(inputEl, latLng);
    }

    google.maps.event.addListener(map, 'click', function (event) {
      moveMarker(event.latLng);
    });

    // Обновляем карту при ручном вводе координат
    inputEl.addEventListener('input', function (event) {
      let geo = `${inputEl.value}`.split(',').map(parseFloat);
      let latLng = new google.maps.LatLng(geo[0], geo[1]);

      marker.setPosition(latLng);
      map.panTo(latLng);
    });
  }

  function dumpGeoPosition (input, latLng) {
    input.value = [latLng.lat(), latLng.lng()].join(',');
  }

  function insertMapsScript (url) {
    const script = document.createElement('script');
    script.src   = url;
    script.async = true;
    script.defer = true;

    document.getElementsByTagName('head')[0].appendChild(script);
  }


});