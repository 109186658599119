const cookies = require('browser-cookies');

const COOKIE_NAME = 'cubes_mode';
const INPUT_NAME = 'cubes_mode';
const CLASS_PREFIX = 'm-cubes-canvas';

function readStateFromSession (defaultValue) {
  return cookies.get(COOKIE_NAME) || defaultValue;
}

function saveStateToSesstion (value) {
  cookies.set(COOKIE_NAME, value);
}

class CubesToggler {
  inputs = [];
  canvas;
  controls;

  constructor (canvas) {
    this.canvas = canvas;
    this.controls = this.buildControls();

    let holder = document.getElementsByClassName(`${CLASS_PREFIX}-header__controls`)[0];
    holder.innerHTML = '';
    holder.appendChild(this.controls);

    this.setState(readStateFromSession('default'));
    this.syncDOM();
  }

  onChange () {
    const value = $(e.target).val();
    this.setState(value);
  }

  setState (value) {
    this.state = value;
    saveStateToSesstion(this.state);
    this.syncDOM();
  }

  syncDOM () {
    const targetClass = this.canvas.className.split(' ').find(c => c.indexOf(`${CLASS_PREFIX}--`) === 0);
    this.canvas.classList.remove(targetClass);
    this.canvas.classList.add(`${CLASS_PREFIX}--${this.state}`);

    this.inputs.forEach(b => {
      if (b.value === this.state) {
        b.checked = true;
        b.parentNode.classList.add('active')
      } else {
        b.checked = false;
        b.parentNode.classList.remove('active')
      }
    });
  }

  buildControls () {
    const buttonsConfig = {default: 'Обычные', large: 'Большие'};

    const wrapper = Object.assign(document.createElement('div'), {className: 'btn-group btn-group-toggle', 'data-toggle': 'buttons'});

    Object.entries(buttonsConfig).forEach(([name, label], index) => {
      const button = Object.assign(document.createElement('label'), {className: 'btn btn-sm btn-outline-secondary'});

      const input = Object.assign(document.createElement('input'), {
        name: INPUT_NAME,
        type: 'radio',
        value: name,
        checked: (this.state === name)
      });

      const l = document.createElement('span');
      l.innerText = label;

      button.appendChild(input);
      button.appendChild(l);

      input.addEventListener('change', e => {
        this.setState(e.target.value);
      });

      this.inputs.push(input);

      wrapper.appendChild(button)
    });

    return wrapper;
  }
}

$(document).on('turbolinks:load', function () {
  Array.from(document.getElementsByClassName(CLASS_PREFIX)).forEach(e => new CubesToggler(e));
});
