function renderCubePopover () {
  const flat = $(this).data('flat');

  return `
      <div class="m-flat-popover-card">
        <div class="mb-2 d-flex">
          ${flat.status}
          <span>&nbsp;&nbsp;</span>
          <strong>${flat.title}</strong>          
        </div>
        <div class="row">
          <div class="col-sm-5">
            <div class="sdf3">
              <img src="${flat.image}" alt="" class="img-fluid"/>          
            </div>              
          </div>
          <div class="col-sm-7">
            <span class="m-rooms">${flat.rooms}к</span>                          
            ${flat.area}
            
            <div class="h5 mt-2 mb-0">
              <strong>${flat.price}</strong>
            </div>
            <div class="text-muted">${flat.price_by_area}</div>
            ${ flat.price_instalment != '—' ? `<div class="text-success"><small class="text-muted">Спеццена:</small><br><strong>${flat.price_instalment}</strong></div>` : '' }            
            <div class="mt-1">
              ${flat.floor} этаж, секция ${flat.entrance}
            </div>
          </div>
        </div>
      </div>`;
}

function initCubePopovers () {
  removePopovers();

  $('[data-flat]').popover({
    trigger: 'hover focus',
    content: renderCubePopover,
    html: true,
    placement: 'top',
    container: 'body',
    offset: '0 5px',
    animation: false,
    boundary: 'viewport',
    delay: 0
  });

  $('[data-flat]').click(function(){
    $(this).popover('dispose');
  });
}

function removePopovers () {
  $('[data-flat]').popover('dispose');
}

$(document).on('turbolinks:load', initCubePopovers);

$(document).on('turbolinks:before-cache', removePopovers);
