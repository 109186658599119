const Turbolinks = require('turbolinks');

const $scrollable = $('html');

Turbolinks.scroll = {};

$(document).on('click', '[data-turbolinks-scroll=false]', function (e) {
  Turbolinks.scroll['top'] = $scrollable.scrollTop();
});

// Use turbolinks:render so the scroll is reset when viewing a cached page too
$(document).on('turbolinks:render', function () {
  if (Turbolinks.scroll['top']) {
    $scrollable.scrollTop(Turbolinks.scroll['top']);
  }

  Turbolinks.scroll = {};
});
