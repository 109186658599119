import * as Raven from 'raven-js/dist/raven.js'


if(process.env.NODE_ENV === 'production'){
    Raven.config('https://57c5d45e99644fa2ba15d4bc22781a0c@sentry.io/163148').install();

    window.addEventListener('error', function (error) {
        Raven.captureException(error);
    });
}

