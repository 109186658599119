import Turbolinks from 'turbolinks';
Turbolinks.start();

import 'jquery'
import {} from 'jquery-ujs'

import '../../assets/styles/app.sass';

import 'bootstrap/js/src/popover';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/button';
import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/tooltip';

import '../kvg/flat-cube-popover';
import '../kvg/flat-modal-card';
import '../kvg/facets';
import '../kvg/facet-filter';
import '../kvg/datepicker';
import '../kvg/daterangepicker';
import '../kvg/draggable';
import '../kvg/sortable';

import '../kvg/active_storage_dnd'
import '../kvg/flash-message'

import '../kvg/turbolinks-persistent-scroll'
import '../kvg/range-inputs'
import '../kvg/popovers'
import '../kvg/selects'
import '../kvg/toggle-all'
import '../kvg/toggle-cubes'

import '../kvg/tooltip'

import '../kvg/kolor-picker'
import '../kvg/gallery'

import '../kvg/simple_form/map'

import '../kvg/raven-init';


import '@primer/octicons/build/build.css'

