import * as $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css'

$(document).on('turbolinks:load', function () {

  $('select.select2').select2({
    theme: "bootstrap4",
    tags: true
  });

});