const selector = '[data-draggable="true"]';

function initDraggable (slider) {
  let isDown = false;
  let startX;
  let scrollLeft;

  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX     = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });

  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });

  slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });

  slider.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x           = e.pageX - slider.offsetLeft;
    const walk        = (x - startX);
    slider.scrollLeft = scrollLeft - walk;
    console.log(walk);
  });
}

$(document).on('turbolinks:load', () => {
  document.querySelectorAll(selector).forEach(initDraggable)
});