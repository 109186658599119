$(document).on('turbolinks:load', function () {

  $('.selectable-toggle-all').each(function (i, button) {
    let $button = $(button);

    let groupId = $button.data('group-id');
    let boxes = $('input[type="checkbox"][name="' + groupId + '"]').toArray();

    $button.text( boxes.find((b) => !b.checked) ? 'Выбрать все' :  'Снять выделение' )

    $button.on('click', e => {
      e.preventDefault();

      if (boxes.find((b) => !b.checked)) {
        boxes.forEach(b => b.checked = true);
        $button.text('Снять выделение');
      } else {
        boxes.forEach(b => b.checked = false);
        $button.text('Выбрать все');
      }
    });

  });
});