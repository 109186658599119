import * as ActiveStorageDragAndDrop from 'active_storage_drag_and_drop';
import * as ActiveStorage from "activestorage";
import "../utils/direct_uploads.js"

import * as $ from 'jquery';

function fileSizeSI (bytes) {
  var e    = Math.log(bytes) / Math.log(1000) | 0;
  var size = (bytes / Math.pow(1000, e) + 0.5) | 0
  return size + (e ? 'kMGTPEZY'[--e] + 'B' : ' Bytes');
}

function createImage (file) {
  var reader = new FileReader();
  var canvas = document.createElement('canvas');

  var ctx = canvas.getContext('2d');

  reader.onload = function (event) {
    var img = new Image();

    img.onload = function () {
      canvas.width  = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    };
    img.src    = event.target.result;
  };

  reader.readAsDataURL(file);

  return canvas;
}


$(document).on('turbolinks:load', () => {

  ActiveStorage.start();

  window.ActiveStorageDragAndDrop = ActiveStorageDragAndDrop;

  window.ActiveStorageDragAndDrop.paintUploadIcon = function paintUploadIcon (iconContainer, id, file, complete) {
    const uploadStatus = (complete ? 'complete' : 'pending');
    const progress     = (complete ? 100 : 0);
    iconContainer.insertAdjacentHTML('beforeend', `
    <div data-direct-upload-id="${id}">
      <div class="direct-upload direct-upload--${uploadStatus}">     
        <div class="for-image"></div>              
        <span class="direct-upload__filename">${file.name}</span>
        <span class="direct-upload__filesize">${fileSizeSI(file.size)}</span>
        
        <div class="direct-upload__progress" style="width: ${progress}%"></div>
      </div>
      <a href='remove' class='direct-upload__remove'>&times;</a>
    </div>
    `);

    var holder = iconContainer.querySelector(`[data-direct-upload-id="${id}"] .for-image`);
    holder.appendChild(createImage(file))
  };


});