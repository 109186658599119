import * as $ from 'jquery';

const POPOVER_DELAY = 150;

function initPopovers () {
  const $popovers = $('[data-popover=true]');
  let visibilityTimer;

  $popovers
    .popover({
      trigger: 'manual',
      placement: 'top',
      container: 'body',
      html: true
    })
    .on('mouseenter.popover', function () {
      visibilityTimer = setTimeout(() => {
        $popovers.not(this).popover('hide');
        $(this).popover('show');
      }, POPOVER_DELAY);
    })
    .on('mouseleave mousedown keyup', function () {
      clearTimeout(visibilityTimer);
      $(this).popover('hide');
    })
    .on('click', function () {
      clearTimeout(visibilityTimer);

      $popovers.popover('hide');
      $popovers.popover('destroy');
      $('.popover').remove();

      $popovers.off('mouseenter.popover');
    });
}

$(document).on('turbolinks:load', initPopovers);