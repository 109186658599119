import * as $ from 'jquery';
import * as _ from 'jquery-ui-sortable';

$(document).on('turbolinks:load', () => {

    $('[data-sortable]')
        .sortable({
            update: function (event, ui) {
                let url = $(this).data("sortable")
                $.post(url, {sorted_ids: $(this).sortable('toArray')})
            }
        });
});