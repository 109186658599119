$(document).on('turbolinks:load', function () {
  if ($('.edu-facets').length) {
    const $flatForm = $('.edu-facets');
    $flatForm.removeAttr('disabled');

    $('.edu-facets :input').on('change', function (e) {
      const url  = $flatForm.attr('action');
      const data = $flatForm.serialize();

      $flatForm.attr('disabled', true);

      if (Turbolinks && Turbolinks.supported) {
        Turbolinks.visit(url + '?' + data);
      } else {
        $flatForm.submit();
      }
    });
  }
});