import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.ru.min'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css'
import * as $ from 'jquery'

import {
  isBefore, parse, format,
  addYears, addMonths, addWeeks, addDays, subYears, subMonths, subWeeks, subDays,
  startOfISOWeek, endOfISOWeek, startOfMonth, endOfMonth, startOfYear, endOfYear
} from 'date-fns'

const RAW_FORMAT = 'yyyy-MM-dd';
const HUMAN_FORMAT = 'dd.MM.yyyy';
const RANGE_SEPARATOR = '__-__';

const LAYOUT_MODES = Object.freeze(['horizontal', 'vertical']);
const DEFAULT_LAYOUT = 'vertical';

const DATEPICKER_DEFAULTS = Object.freeze({
  format: 'dd.mm.yyyy',
  language: 'ru',

  todayHighlight: true,
  autoclose: true,
  clearBtn: true,

  orientation: "bottom left",
  container: '.site__content'
});

function formatDate (date) {
  return format(date, HUMAN_FORMAT);
}

function createInput (pickerSettings = {}, defaultValue = null, changeTracker = () => ({})) {
  const placeholder = pickerSettings.placeholderDate ? formatDate(pickerSettings.placeholderDate) : `__.__.____`;
  const input = $(`<input type="text" class="form-control form-control-sm" placeholder="${placeholder}">`);

  input
    .datepicker(Object.assign({}, DATEPICKER_DEFAULTS, pickerSettings))
    .on('change clearDate', () => changeTracker());

  if (defaultValue) {
    input.val(defaultValue);
  }

  return input;
}

function extractDate (value, {useFormat = RAW_FORMAT, now = new Date}) {
  if (!value) {
    return null;
  }

  return parse(value, useFormat, now, {weekStartsOn: 1, useAdditionalDayOfYearTokens: true, useAdditionalWeekYearTokens: true});
}

function buildPresets (now, min, max) {
  return {
    'На этой неделе': [startOfISOWeek(now), now],
    'В этом месяце': [startOfMonth(now), now],
    'В прошлом месяце': [startOfMonth(subMonths(now, 1)), endOfMonth(subMonths(now, 1))],
    'Последние 30 дней': [subDays(now, 29), now],
    'В этом году': [startOfYear(now), now],
    'Прошлые 12 мес.': [subMonths(now, 12), now],
    'Прошлый год': [startOfYear(subYears(now, 1)), endOfYear(subYears(now, 1))],
    'За всё время': [min || now, max || now]
  };
}


class DateRangePicker {
  hasChanges = false;
  minDate = null;
  maxDate = null;
  usePresets = false;
  presets = {};
  submitText = 'Применить';

  constructor ($item) {
    this.$item = $($item);

    const now = new Date;

    this.maxDate = extractDate(this.$item.attr('edu-facet-range-max'), {useFormat: RAW_FORMAT, now: now});
    this.minDate = extractDate(this.$item.attr('edu-facet-range-min'), {useFormat: RAW_FORMAT, now: now});

    this.usePresets = !!this.$item.attr('edu-facet-range-presets');

    let layout = this.$item.attr('edu-facet-range-layout');

    if (!LAYOUT_MODES.includes(layout)) {
      layout = DEFAULT_LAYOUT;
    }

    this.layout = layout;
    this.submitText = this.layout === 'horizontal' ? '→' : 'Применить';

    const [initialFrom, initialTo] = this.$item.val().split(RANGE_SEPARATOR);

    this.initial = Object.freeze({
      from: initialFrom,
      to: initialTo
    });

    if (this.usePresets) {
      this.presets = buildPresets(now, this.minDate, this.maxDate);
    }

    this.render();
    this.run();
  }

  get isDisabled () {
    return !this.minDate && !this.maxDate;
  }

  run () {
    this.setDefaultDates();
    this.markAsChanged(false);
    this.handleChange();
  }

  dump () {
    const value = [this.$fromInput.val(), this.$toInput.val()].join(RANGE_SEPARATOR);
    this.$item.val(value);
    this.$item.trigger('change');
    this.markAsChanged(false);
  }

  markAsChanged (isChanged = true) {
    this.hasChanges = isChanged;

    this.$submit.attr('disabled', !this.hasChanges);
  }

  handleChange () {
    const from = this.$fromInput.datepicker('getDate');
    const to = this.$toInput.datepicker('getDate');

    const maxFrom = !!to && isBefore(to, this.maxDate) ? to : this.maxDate;
    this.$fromInput.datepicker('setEndDate', maxFrom);

    const minTo = !!from && isBefore(from, this.minDate) ? this.minDate : from;
    this.$toInput.datepicker('setStartDate', minTo);

    if ((!!from && formatDate(from) !== this.initial.from) || (!from && this.initial.from)) {
      return this.markAsChanged(true);
    }

    if ((!!to && formatDate(to) !== this.initial.to) || (!to && this.initial.to)) {
      return this.markAsChanged(true);
    }
  }

  render () {
    this.$holder = $(`
      <div class="m-date-range m-date-range--${this.layout}">
        <div class="m-date-range__input --presets">                    
        </div>
        <div class="m-date-range__input --from">
          <label for="">C</label>
          
        </div>        
        <div class="m-date-range__input --delimiter">–</div>
        <div class="m-date-range__input --to">
          <label for="">По</label>
        </div>
        <div class="m-date-range__actions --actions">
        
        </div>
      </div>
    `);

    this.$fromInput = createInput({
      defaultViewDate: this.initial.from || new Date,
      startDate: this.minDate,
      endDate: this.maxDate,
      placeholderDate: this.minDate
    }, this.initial.from, this.handleChange.bind(this));

    this.$toInput = createInput({
      defaultViewDate: this.initial.to || new Date,
      startDate: this.minDate,
      endDate: this.maxDate,
      placeholderDate: this.maxDate
    }, this.initial.to, this.handleChange.bind(this));

    if (this.usePresets) {
      this.$select = $('<select class="form-control form-control-sm">');

      let options = ['<option>Выбрать</option>'].concat(
        Object.keys(this.presets).map(k => `<option value="${k}">${k}</option>`)
      );

      this.$select.html(options);

      this.$select.on('change', () => {
        const key = this.$select.val();
        let [from, to] = this.presets[key];
        this.$fromInput.val(formatDate(from)).trigger('change');
        this.$toInput.val(formatDate(to)).trigger('change');

        this.dump();
      });

      const $activeOption = this.$select.children('option').toArray().find(o => {
        const period = this.presets[$(o).val()];

        if (!period) {
          return false;
        }

        const [from, to] = period;

        if (from && to) {
          return formatDate(from) === this.$fromInput.val() && formatDate(to) === this.$toInput.val();
        }

        return false;
      });

      if ($activeOption) {
        $($activeOption).attr('selected', true);
      }
    }

    this.$submit = $(`<button type="button" class="btn btn-outline-secondary btn-sm m-date-range__submit" disabled="${!this.hasChanges}" title="Применить">${this.submitText}</button>`);
    this.$submit.on('click', () => this.dump());

    this.$fromInput.attr('disabled', this.isDisabled);
    this.$toInput.attr('disabled', this.isDisabled);
    this.$submit.attr('disabled', this.isDisabled);

    this.$holder.find('.--presets').append(this.$select);
    this.$holder.find('.--from').append(this.$fromInput);
    this.$holder.find('.--to').append(this.$toInput);
    this.$holder.find('.--actions').append(this.$submit);

    this.$item.parent().append(this.$holder);
    this.$item.hide();
  }

  setDefaultDates () {
    this.$fromInput.datepicker('update', this.initial.from);
    this.$toInput.datepicker('update', this.initial.to);
  }
}

$(document).on('turbolinks:load', () => {
  $('.m-date-range').remove();

  $('.edu-date-range-picker').each((_, picker) => {
    new DateRangePicker(picker);
  });
});