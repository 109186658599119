import * as $ from 'jquery'
import Cleave from 'cleave.js'
import noUiSlider from 'nouislider'
import 'nouislider/distribute/nouislider.css'

const NUMBER_STYLE = {
  numeral: true,
  numeralDecimalMark: '.',
  numeralDecimalScale: 2,
  numeralIntegerScale: 20,
  numeralThousandsGroupStyle: 'thousand',
  numeralPositiveOnly: true,
  stripLeadingZeroes: true,
  delimiter: ' '
};

const RANGE_SEPARATOR = '__-__';

const initRangeInput = function initRangeInput (input) {
  const $input = $(input);

  // if ($input.is(':hidden')) {
  //   return;
  // }

  $input.parent().find('.m-range, .m-slider').remove();

  const numeralFormatter = new Cleave.NumeralFormatter(
    NUMBER_STYLE.numeralDecimalMark,
    NUMBER_STYLE.numeralIntegerScale,
    NUMBER_STYLE.numeralDecimalScale,
    NUMBER_STYLE.numeralThousandsGroupStyle,
    NUMBER_STYLE.numeralPositiveOnly,
    NUMBER_STYLE.stripLeadingZeroes,
    NUMBER_STYLE.delimiter);

  const values = $input.val().split(RANGE_SEPARATOR);

  const min     = $input.attr('edu-facet-range-min');
  const max     = $input.attr('edu-facet-range-max');


  const type    = $input.attr('edu-facet-range-type');
  const isFloat = type === 'float';
  const step    = (isFloat ? .1 : 1);


  const $from = $('<input type="text" />').val(values[0]).attr('placeholder', min);
  const $to   = $('<input type="text" />').val(values[1]).attr('placeholder', max);

  const dump = function (event) {
    $input.val($from.val().replace(/\s+/gi, '') + RANGE_SEPARATOR + $to.val().replace(/\s+|,/gi, ''));
    $input.trigger('change');
  };

  $from.attr('min', min).attr('max', max).on('input', dump);
  $to.attr('min', min).attr('max', max).on('input', dump);

  const $slider = $('<div />').addClass('m-slider');

  $input.after($slider).after(
    $('<div class="m-range" />').append($from).append($to)
  );

  $input.hide();


  new Cleave($from.get(0), NUMBER_STYLE);
  new Cleave($to.get(0), NUMBER_STYLE);


  if (min == max) {
    $from.attr('disabled', true);
    $to.attr('disabled', true);
    return;
  }

  noUiSlider.create($slider.get(0), {
    start: [
      values[0] ? (isFloat ? parseFloat(values[0]) : parseInt(values[0])) : min,
      values[1] ? (isFloat ? parseFloat(values[1]) : parseInt(values[1])) : max
    ],
    connect: true,
    step: step,
    range: {
      'min': isFloat ? parseFloat(min) : parseInt(min),
      'max': isFloat ? parseFloat(max) : parseInt(max)
    }
  });

  const slider = $slider.get(0);

  slider.noUiSlider.on('update', function (values, handle) {
    var value = values[handle];

    if (!isFloat) {
      value = parseInt(value);
    }

    if (handle) {
      $to.val(numeralFormatter.format(value.toString()) || '');
    } else {
      $from.val(numeralFormatter.format(value.toString()) || '');
    }
  });

  slider.noUiSlider.on('end', function (values, handle) {
    dump();
    $from.trigger('change'); // trigger change for reload flats list
  });

  $from.on('change', function () {
    slider.noUiSlider.set([this.value, null]);
  });

  $to.on('change', function () {
    slider.noUiSlider.set([null, this.value]);
  });

};

$(document).on('turbolinks:load', () => {
  $('[edu-facet-range="number"]').each(function (index, item) {
    initRangeInput(item);
  });
});
