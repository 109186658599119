import * as $ from 'jquery';

function initFlashMessage () {
  var $msg = $('#flash-messages');

  if ($msg) {
    setTimeout(() => $msg.fadeOut(1000), 1000);
  }
}

$(document).on('ready', initFlashMessage);
$(document).on('turbolinks:load', initFlashMessage);
