// Скопировал и чуть поправил скрипты https://github.com/PitPik/tinyColorPicker
// чтобы всё работало с новыми турболинками и вебпакером

import './kolor-picker/colors'
import './kolor-picker/jqColorPicker'
import './kolor-picker/color-sampler'
import './kolor-picker/kolor-picker'

let colorPicker  = $();

$(document).on('turbolinks:load', function () {
  $('[data-widget-kolor-picker]').each(function () {
    const element = $(this);
    const options = element.data('widget-kolor-picker') || {};

    console.log(options);

    options.onSelect = function (color) {
      element.val(color).change();
    };
    element.addClass('kolor-picker-input')
      .attr('autocomplete', 'off')
      .kolorPicker(options)
      .wrap('<span class="kolor-picker-wrapper" />');
  });


  if (colorPicker.length) {
    $('body').append(colorPicker);
  }

});

$(document).on('turbolinks:before-unload', function() {
  if (!colorPicker.length) {
    colorPicker = $('.cp-color-picker');
  }
});
